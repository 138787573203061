import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Octocyclop from '../components/octocyclop'

import * as d3 from 'd3'

const distortion = 4
const time = 2000
const imageWidth = 400
const imageHeight = 400

export default () => {
  const ref = useRef(null)
  console.log(
    '_______ ref && ref.current && ref,current',
    ref && ref.current && ref.current
  )
  useEffect(() => {
    const scale = Math.max(
      window.innerHeight / imageHeight,
      window.innerWidth / imageWidth
    )
    d3.interval(function (elapsed) {
      d3.selectAll('svg path').each(function (d, i) {
        d3.select(this)
          .transition()
          .duration(Math.random() * time)
          .attr(
            'transform',
            `translate(${Math.floor(Math.random() * distortion)}, ${Math.floor(
              Math.random() * distortion - 500
            )})
            scale(${scale})`
          )
          // .attr('opacity', Math.random() * 5 + 5)
          .transition()
          .duration(Math.random() * time)
          .attr(
            'transform',
            `translate(${Math.floor(Math.random() * distortion)}, ${Math.floor(
              Math.random() * distortion - 500
            )})
            scale(${scale})`
          ) // .attr('opacity', 1)
      })
    }, time)
  }, [])
  return (
    <Styles>
      <Octocyclop ref={ref} width={'100vw'} height={'100vw'} />
    </Styles>
  )
}

const Styles = styled.div`
  svg {
    background-color: purple;
    * {
      //transform-origin: top right;
    }
  }
`
